import Typography from "typography"
import Wordpress2016 from "typography-theme-wordpress-2016"

export const theme = {
  titleColor: "#7675b9",
  subTitleColor: "#4b91c9",
  linkColor: "#57babb",
}

Wordpress2016.overrideThemeStyles = () => {
  return {
    "a.gatsby-resp-image-link": {
      boxShadow: `none`,
    },
    h1: {
      fontFamily: ["Ubuntu", "sans-serif"].join(","),
      color: theme.titleColor,
    },
    h2: {
      color: theme.subTitleColor,
    },
    a: {
      color: theme.linkColor,
    },
  }
}
Wordpress2016.headerFontFamily = ["Ubuntu", "sans-serif"]
Wordpress2016.bodyFontFamily = ["Ubuntu", "sans-serif"]
delete Wordpress2016.googleFonts

const typography = new Typography(Wordpress2016)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
